// extracted by mini-css-extract-plugin
export var container = "CoreValuesSection-module--container--0cbc6";
export var coreValuesUpperTitle = "CoreValuesSection-module--coreValuesUpperTitle--a931e";
export var coreValuesWrapper = "CoreValuesSection-module--coreValuesWrapper--3d641";
export var headerWrapper = "CoreValuesSection-module--headerWrapper--5b55f";
export var mainHeader = "CoreValuesSection-module--mainHeader--46620";
export var missionContainer = "CoreValuesSection-module--missionContainer--763af";
export var opacityEnter = "CoreValuesSection-module--opacityEnter--99682";
export var opacityExit = "CoreValuesSection-module--opacityExit--c95d7";
export var rollDown = "CoreValuesSection-module--rollDown--cefc3";
export var rollUp = "CoreValuesSection-module--rollUp--44e3f";
export var secondaryHeader = "CoreValuesSection-module--secondaryHeader--1bda3";
export var slideInDown = "CoreValuesSection-module--slideInDown--a48ef";
export var slideOutUp = "CoreValuesSection-module--slideOutUp--c5567";
export var splashEnter = "CoreValuesSection-module--splashEnter--208b3";
export var splashExit = "CoreValuesSection-module--splashExit--aa676";
export var upperTitle = "CoreValuesSection-module--upperTitle--b230e";
export var upperTitleSecondary = "CoreValuesSection-module--upperTitleSecondary--d18de";
export var valueDescription = "CoreValuesSection-module--valueDescription--b2430";
export var valueImage = "CoreValuesSection-module--valueImage--f197b";
export var valueItem = "CoreValuesSection-module--valueItem--16cec";
export var valueTitle = "CoreValuesSection-module--valueTitle--bba0e";
export var visionContainer = "CoreValuesSection-module--visionContainer--3583c";
export var visionUpperTitle = "CoreValuesSection-module--visionUpperTitle--4e204";
export var visionWrapper = "CoreValuesSection-module--visionWrapper--59aef";
export var wrapper = "CoreValuesSection-module--wrapper--45eb9";