import React from 'react';
import Image from '@/components/elements/Image';
import { CoreValuesSectionTypes } from './CoreValuesSection.types';
import * as styles from './CoreValuesSection.module.scss';

export default function CoreValuesSection({
  vissionUpperTitle,
  coreValues,
  coreValuesUpperTitle,
  missionTitle,
  missionUpperTitle,
  visionTitle,
}: CoreValuesSectionTypes) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.visionContainer}>
          <div className={styles.visionWrapper}>
            <div className={styles.headerWrapper}>
              <p className={styles.visionUpperTitle}>{vissionUpperTitle}</p>
              <h2 className={styles.mainHeader}>{visionTitle}</h2>
            </div>
          </div>
          <div className={styles.missionContainer}>
            <p className={styles.upperTitleSecondary}>{missionUpperTitle}</p>
            <h3 className={styles.secondaryHeader}>{missionTitle}</h3>
          </div>
        </div>
        <div>
          <p className={styles.coreValuesUpperTitle}>{coreValuesUpperTitle}</p>
          {coreValues && (
            <ul className={styles.coreValuesWrapper}>
              {coreValues.map(value => (
                <li key={value._uid} className={styles.valueItem}>
                  {value.image && <Image data={value.image} fluid className={styles.valueImage} />}
                  <h4 className={styles.valueTitle}>{value.title}</h4>
                  <p className={styles.valueDescription}>{value.description}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
